<template>
    <div
            class="rounded-lg fill-height"
            :class="editItems ? 'grid-item':''"
            :style="$vuetify.breakpoint.width < 600 ? {gridColumn: 'span 1', gridRow: 'span 1'} : editFields ? {gridColumn: 'span ' + cols / 2, gridRow: 'span ' + (display_rules !== null ? display_rules.rows: '')} : {gridColumn: 'span ' + (display_rules !== null ? display_rules.cols: ''), gridRow: 'span ' + (display_rules !== null ?display_rules.rows: '')}"
    >

        <Widget
                class="fill-height"
                :inItem="true"
                v-show="!editFields"
                v-if="type === 'widget'"
                :edit="editItems"
                :widget="data"
                :path="path"
                :cols="cols"
                @loaded="(e) => el = e"
        />

        <div
                v-if="type === 'fieldgroup'"
                class="fill-height"
        >
            <div class="fill-height overflow-hidden" :class="[$store.getters['style/classes'].container, editItems ? 'handle':'']" v-if="!editFields" style="position: relative">
                <v-row class="mx-0 px-3" align="center">
                    <div class="sub-title-2 mb-2 font-weight-bold" v-if="data.name !== 'default' && data.name !== ''">{{ data.name | firstCapital }}</div>
                </v-row>
                <FieldGroup
                        v-model="item"
                        :path="path"
                        :pathId="$route.params[$route.name]"
                        :fields="data.fields"
                        :mode="mode"
                        :split="display_rules.cols % cols === 0 ? split : display_rules.cols % cols"
                        :title="data.name"
                        :pevents="pevents"
                        @newItemCreated="(e) => $emit('newItemCreated', e)"
                        @saved="(e) => pevents.notify('update', e)"
                        @loaded="e => el = e"
                        @bottomSheet="e => $emit('bottomSheet', e)"
                />

                <div
                        class="overflow-hidden resizer"
                        style="position: absolute; bottom: -4px; right: -4px; width: 40px; height: 40px; z-index: 1;"
                        v-show="editItems"
                >
                    <v-hover v-slot:default="{ hover }">
                        <div
                                @mouseenter="(e) => makeResizableDiv(e, e.target.parentElement.parentElement, e.target.parentElement.parentElement.parentElement.parentElement)"
                                class="text-center transition-cubic"
                                :style="hover ? {backgroundColor: $tools.transparentize('black', 0.2, $vuetify)}:{}"
                                style="position: relative; bottom: -4px; right: -15px;transform: rotateZ(45deg); height: 60px; display: flex; align-items: center; justify-content: start; cursor: nwse-resize"
                        >
                            <v-icon color="black2white" size="30" style="position: relative; top: 0px; left: -4px">mdi-chevron-right</v-icon>
                        </div>
                    </v-hover>
                </div>
            </div>
        </div>

        <div
                v-if="type === 'status'"
                class="fill-height"
        >
            <div class="rounded-lg cell fill-height elevation-2 overflow-hidden" :class="editItems ? 'handle':''" style="position: relative">
                <v-row class="mx-0">
                    <v-col cols="12" class="px-1 py-3">
                        <v-row align="center" class="mx-0">
                            <v-icon size="18" class="ml-2 mr-4">mdi-transit-connection-variant</v-icon>
                            <div class="sub-title-2 font-weight-bold pt-1">{{ data.fields['status'].label }}</div>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                        <StatusField
                                v-model="item"
                                :fields="data.fields"
                                :fieldName="data.name"
                                :path="path"
                                :mode="mode"
                                :pevents="pevents"
                                @loaded="e => el = e"
                                @newItemCreated="(e) => $emit('newItemCreatedStatus', e)"
                        />
                    </v-col>
                </v-row>
                <div
                        class="overflow-hidden resizer"
                        style="position: absolute; bottom: -4px; right: -4px; width: 40px; height: 40px; z-index: 1;"
                        v-show="editItems"
                >
                    <v-hover v-slot:default="{ hover }">
                        <div
                                @mouseenter="(e) => makeResizableDiv(e, e.target.parentElement.parentElement, e.target.parentElement.parentElement.parentElement.parentElement)"
                                class="text-center transition-cubic"
                                :style="hover ? {backgroundColor: $tools.transparentize('black', 0.2, $vuetify)}:{}"
                                style="position: relative; bottom: -4px; right: -15px;transform: rotateZ(45deg); height: 60px; display: flex; align-items: center; justify-content: start; cursor: nwse-resize"
                        >
                            <v-icon color="black2white" size="30" style="position: relative; top: 0px; left: -4px">mdi-chevron-right</v-icon>
                        </div>
                    </v-hover>
                </div>
            </div>
        </div>

        <div
                v-if="type === 'extra'"
                class="fill-height"
        >
            <div :class="[$store.getters['style/classes'].container, editItems ? 'handle':'']" class="fill-height overflow-hidden" style="position: relative">
                <ExtraFieldGroup
                        v-model="extraItems"
                        :path="path + '/' + $route.params[$route.name]"
                        :pevents="pevents"
                        :edit="editItems"
                        :update="methods.includes('update')"
                        @loaded="e => el = e"
                />
                <div
                        class="overflow-hidden resizer"
                        style="position: absolute; bottom: -4px; right: -4px; width: 40px; height: 40px; z-index: 1;"
                        v-show="editItems"
                >
                    <v-hover v-slot:default="{ hover }">
                        <div
                                @mouseenter="(e) => makeResizableDiv(e, e.target.parentElement.parentElement, e.target.parentElement.parentElement.parentElement.parentElement)"
                                class="text-center transition-cubic"
                                :style="hover ? {backgroundColor: $tools.transparentize('black', 0.2, $vuetify)}:{}"
                                style="position: relative; bottom: -4px; right: -15px;transform: rotateZ(45deg); height: 60px; display: flex; align-items: center; justify-content: start; cursor: nwse-resize"
                        >
                            <v-icon color="black2white" size="30" style="position: relative; top: 0px; left: -4px">mdi-chevron-right</v-icon>
                        </div>
                    </v-hover>
                </div>
            </div>
        </div>

        <div
                v-if="type === 'set'"
                class="fill-height elevation-2 rounded-lg"
        >
            <Set
                    class="fill-height overflow-hidden rounded-lg"
                    :routeType="data.type"
                    :routeParent="data.endpoint"
                    :embed="data"
                    :parentItem="item"
                    :routeChildren="data.children"
                    :edit="editItems"
                    :pevents="pevents"
                    @resize="(e) => makeResizableDiv(e, e.target.parentElement.parentElement.parentElement, e.target.parentElement.parentElement.parentElement.parentElement.parentElement)"
                    @loaded="e => el = e"
            />
        </div>

        <div
                v-if="type === 'activity'"
                class="fill-height"
        >
            <div class="elevation-2 cell overflow-hidden rounded-lg fill-height" :class="editItems ? 'handle':''" style="position: relative">
                <Activities
                        :path="path + '/' + $route.params[$route.name]"
                        :pevents="pevents"
                        :edit="editItems"
                        @loaded="e => el = e"
                />
                <div
                        class="overflow-hidden resizer"
                        style="position: absolute; bottom: -4px; right: -4px; width: 40px; height: 40px; z-index: 1;"
                        v-show="editItems"
                >
                    <v-hover v-slot:default="{ hover }">
                        <div
                                @mouseenter="(e) => makeResizableDiv(e, e.target.parentElement.parentElement, e.target.parentElement.parentElement.parentElement.parentElement)"
                                class="text-center transition-cubic"
                                :style="hover ? {backgroundColor: $tools.transparentize('black', 0.2, $vuetify)}:{}"
                                style="position: relative; bottom: -4px; right: -15px;transform: rotateZ(45deg); height: 60px; display: flex; align-items: center; justify-content: start; cursor: nwse-resize"
                        >
                            <v-icon color="black2white" size="30" style="position: relative; top: 0px; left: -4px">mdi-chevron-right</v-icon>
                        </div>
                    </v-hover>
                </div>
            </div>
        </div>

        <div
                v-if="type === 'comment'"
                class="fill-height"
        >
            <div class="elevation-2 cell rounded-lg overflow-hidden fill-height" :class="editItems ? 'handle':''" style="position: relative">
                <Comments
                        :path="path + '/' + $route.params[$route.name]"
                        :readOnly="!methods.includes('update')"
                        :edit="editItems"
                        :pevents="pevents"
                        :update="methods.includes('update')"
                        :destroy="methods.includes('destroy')"
                        @loaded="e => el = e"
                />
                <div
                        class="overflow-hidden resizer"
                        style="position: absolute; bottom: -4px; right: -4px; width: 40px; height: 40px; z-index: 1;"
                        v-show="editItems"
                >
                    <v-hover v-slot:default="{ hover }">
                        <div
                                @mouseenter="(e) => makeResizableDiv(e, e.target.parentElement.parentElement, e.target.parentElement.parentElement.parentElement.parentElement)"
                                class="text-center transition-cubic"
                                :style="hover ? {backgroundColor: $tools.transparentize('black', 0.2, $vuetify)}:{}"
                                style="position: relative; bottom: -4px; right: -15px;transform: rotateZ(45deg); height: 60px; display: flex; align-items: center; justify-content: start; cursor: nwse-resize"
                        >
                            <v-icon color="black2white" size="30" style="position: relative; top: 0px; left: -4px">mdi-chevron-right</v-icon>
                        </div>
                    </v-hover>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "ItemWidget",
        props: ['value', 'data', 'editItems', 'cols', 'pevents', 'path', 'mode', 'type', 'editFields', 'extraItems', 'methods', 'unit', 'full'],
        components: {
            draggable: () => import('vuedraggable'),
            Widget: () => import('@/components/Widget.vue'),
            TextField: () => import('@/components/fields/TextField.vue'),
            Button: () => import('@/components/ui/Button.vue'),
            FieldGroup: () => import('@/components/FieldGroup.vue'),
            Activities: () => import('@/components/Activities.vue'),
            Comments: () => import('@/components/Comments.vue'),
            Set: () => import('@/pages/dashboard/Set.vue'),
            ProgressField: () => import('@/components/fields/ProgressField.vue'),
            StatusField: () => import('@/components/fields/StatusField.vue'),
            ExtraFieldGroup: () => import('@/components/ExtraFieldGroup.vue'),
        },
        data() {
            return {
                split: 1,
                el: null
            }
        },
        computed: {
            item: {
                get(){
                    return this.value
                },
                set(val){
                    this.$emit('input', val)
                }
            },
            display_rules: {
                get(){
                    return this.data.display_rules || {
                        min_cols: 4,
                        cols: 8,
                        rows: 8
                    }
                },
                set(val) {
                    this.$set(this.data, 'display_rules', val)
                }
            }
        },
        watch: {
            el(val){
                new ResizeObserver(this.computeChildShape).observe(val)
            }
        },
        methods: {
            makeResizableDiv(div, child, container) {
                const handle = div.target

                let that = this

                handle.addEventListener('mousedown', function(e) {
                    e.preventDefault()
                    window.addEventListener('mousemove', resize)
                    window.addEventListener('mouseup', stopResize)
                })

                function resize(e) {
                    let newPosX = e.pageX - (child.getBoundingClientRect().left - 10)
                    let newPosY = (e.pageY - (child.getBoundingClientRect().top - 10) - window.scrollY)

                    let dX = child.getBoundingClientRect().right - container.getBoundingClientRect().right
                    let dY = child.getBoundingClientRect().bottom - container.getBoundingClientRect().bottom

                    child.style.width = newPosX + 'px'
                    //child.style.height = newPosY + 'px'

                    if(dX > 30){
                        if(that.display_rules.cols + 1 <= that.cols) {
                            that.$set(that.display_rules, 'cols', that.display_rules.cols + 1)
                        }
                    }
                    if(dX < -60){
                        if(that.display_rules.cols - 1 >= that.display_rules.min_cols)
                            that.$set(that.display_rules, 'cols', that.display_rules.cols - 1)
                    }
                    /*if(dY > 30){
                        that.$set(that.display_rules, 'rows', that.display_rules.rows + 1)
                    }
                    if(dY < -60){
                        if(that.display_rules.rows - 1 >= that.display_rules.min_rows)
                            that.$set(that.display_rules, 'rows', that.display_rules.rows - 1)
                    }*/
                }

                function stopResize() {
                    child.style.removeProperty('width');
                    child.style.removeProperty('height');

                    window.removeEventListener('mousemove', resize)
                }
            },
            computeSplit(){
                if(this.$vuetify.breakpoint.width > 1904){
                    this.split = 4
                }
                else if(this.$vuetify.breakpoint.width > 1264 && this.$vuetify.breakpoint.width <= 1904){
                    this.split = 3
                }
                else if(this.$vuetify.breakpoint.width > 960 && this.$vuetify.breakpoint.width <= 1264){
                    this.split = 2
                }
                else if(this.$vuetify.breakpoint.width > 600 && this.$vuetify.breakpoint.width <= 960){
                    this.split = 2
                }
                else {
                    this.split = 1
                }
            },
            computeShape(){
                if(!this.data.display_rules) {
                    if (this.type === 'fieldgroup') {
                        if(this.full){
                            this.display_rules = {
                                min_cols: 8,
                                min_rows: 2,
                                cols: 32,
                                rows: parseInt(this.data.fieldsArray.length / this.split) + 2
                            }
                        }
                        else {
                            this.display_rules = {
                                min_cols: 8,
                                min_rows: 2,
                                cols: 16,
                                rows: parseInt(this.data.fieldsArray.length / this.split) + 1
                            }
                        }
                    } else if (this.type === 'status') {
                        this.display_rules = {
                            min_cols: 4,
                            min_rows: 2,
                            cols: 32,
                            rows: 3
                        }
                    } else if (this.type === 'extra') {
                        this.display_rules = {
                            min_cols: 4,
                            min_rows: 1,
                            cols: 32,
                            rows: this.data.items.length === 0 ? 1 : 2
                        }
                    } else if (this.type === 'set') {
                        this.display_rules = {
                            min_cols: 6,
                            min_rows: 2,
                            cols: 32,
                            rows: 4
                        }
                    } else if (this.type === 'activity') {
                        this.display_rules = {
                            min_cols: 4,
                            min_rows: 3,
                            cols: 16,
                            rows: 3
                        }
                    } else if (this.type === 'comment') {
                        this.display_rules = {
                            min_cols: 4,
                            min_rows: 3,
                            cols: 16,
                            rows: 3
                        }
                    } else {
                        this.display_rules = {
                            min_cols: 4,
                            cols: 8,
                            rows: 8
                        }
                    }
                }
            },
            computeChildShape(e) {
                /*if(this.type === 'status') {
                    if(this.item['status'].length > 0)
                        this.display_rules.rows = parseInt((e[0].target.clientHeight - 80) / this.unit)
                }
                else if(this.type === 'widget' || this.type === 'activity' || this.type === 'comment') {
                }
                else {
                    //this.display_rules.rows = parseInt(e[0].target.clientHeight / this.unit)
                }*/
            }
        },
        created() {
            this.computeSplit()
            this.computeShape()
        },
        updated(){
            this.computeShape()
        }
    }
</script>

<style scoped>
    .grid-item {
        align-items: center;
        box-shadow: 0 0 0 2px rgba(0, 120, 255, 0.5) inset;
        background-color: rgba(0, 120, 255, 0.5) !important;
    }
    .handle {
        cursor: grab;
    }
    .handle > div:not(.resizer) {
        pointer-events: none;
    }
</style>
